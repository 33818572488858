import { useEffect, useState } from 'react';
import { useStore } from '@nanostores/react';
import { $isReady, getTeaserBatch } from '@innhold/core/front/stores/blend-store';
import type { TeaserResponseType } from '@innhold/core/front/types';
import type { BatchLayoutType } from '../../types';
import { getBatchSize } from '../../utils/batches';
import { BatchLayout } from './BatchLayout';

type BatchProps = {
    layout: BatchLayoutType;
    offset: number;
};

export const Batch = ({ layout, offset }: BatchProps) => {
    const isBlendReady = useStore($isReady);

    const [teasers, setTeasers] = useState<TeaserResponseType[]>([]);

    useEffect(() => {
        if (!isBlendReady) {
            return;
        }

        const fetchTeasersBatch = async () => {
            const batch = await getTeaserBatch(offset, getBatchSize(layout));
            setTeasers(batch);
        };

        fetchTeasersBatch();
    }, [isBlendReady, offset, layout]);

    if (!teasers.length) {
        return <div />;
    }

    return <BatchLayout teasers={teasers} layout={layout} />;
};
